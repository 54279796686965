#scrollIndicator {
    position: fixed;
    z-index: 90;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 0.75rem;
    background: #ccc;
    opacity: 0.75;

    #scrollProgress {
        width: 0%;
        height: 100%;
        background: -webkit-linear-gradient(left, rgba(252, 176, 69, 0.9) 0%,rgba(253, 29, 29, 0.8) 50%, rgba(131, 58, 180, 0.7) 100%);
        background: -o-linear-gradient(left, rgba(252, 176, 69, 0.9) 0%,rgba(253, 29, 29, 0.8) 50%, rgba(131, 58, 180, 0.7) 100%);
        background: linear-gradient(to right, rgba(252, 176, 69, 0.9) 0%,rgba(253, 29, 29, 0.8) 50%, rgba(131, 58, 180, 0.7) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcb045',endColorstr='#833ab4',GradientType=1);
    }
}
