body {
    position: relative;
    overflow-x: hidden;
    font-family: "amble_light",Helvetica,Arial,sans-serif;
    font-size: 16px;
    color: $secondary-dark;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
    font-family: "big_john_regular",Helvetica,Arial,sans-serif;
}

a,
a:hover,
a:focus,
a:active,
a.active,
.btn:focus,
.btn:active,
.btn.active,
button:focus,
button:active,
button.active {
    outline: none !important;
    box-shadow: none !important;
}

a {
    color: $secondary;
    transition: 0.3s;
}

a:hover,
a:focus,
a:active,
a.active {
    color: $primary;
    transition: 0.3s;
}

p {
    line-height: 1.75;
}

p.large {
    font-size: 1.25rem;
}

.text-muted {
    color: #777;
    font-size: 0.8rem;
}

.text-primary {
    color: $primary;
}

.img-centered {
    margin: 0 auto;
}

.bg-almost-white {
    background-color: $almost-white;
}

.bg-light-gray {
    background-color: $light-gray;
}

.bg-dark-gray {
    background-color: $dark;
}

.frame {
    border: 3px solid #afafaf;
    transition: 0.3s;
    max-width: 338px;
    min-height: 157.5px;
    margin: auto;

    &:hover {
        border-color: #000;
        transition: 0.5s;
    }
}

.btn {
    font-family: "amble_bold",Helvetica,Arial,sans-serif;
    font-weight: 400;
    color: #fff;
    border-radius: 0;
}

.btn-xl {
    padding: 2rem 6rem;
    font-size: 1.875rem;
    border: 3px solid #fff;
}

.btn-xl:hover,
.btn-xl:focus,
.btn-xl:active,
.btn-xl.active {
    color: #fff;
    background-color: $half-black;
    border-color: #000;
    transition: 0.5s;
}

.btn-lg {
    padding: 0.5rem 3.5rem;
    font-size: 1.5rem;
    color: #000;
    border: 3px solid #000;
}

.btn-lg:hover,
.btn-lg:focus,
.btn-lg:active,
.btn-lg.active {
    color: $dark;
    background-color: $half-white;
    border-color: $dark;
    transition: 0.5s;
}
￼
.btn-primary {
    color: #fff;
    background-color: $primary;
    border: 2px solid #fff;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
    border-color: #000;
    color: #fff;
    background-color: $secondary;
}

.btn-secondary {
    padding: 0.5rem 1.5rem;
    font-size: 1.2rem;
    color: #000;
    background: none;
    border: 2px solid #000;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active {
    color: $dark;
    background-color: $half-white;
    border-color: $dark;
    transition: 0.5s;
}

.navbar-trigger {
    position: fixed;
    top: 2rem;
    left: 2rem;
    z-index: 100;
    padding: 0 !important;

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
        background-color: #fff;
        box-shadow: 0 0 2px $black;
    }

    &:hover {

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            background-color: $primary !important;
        }
    }

    &:focus,
    &:active {

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            background-color: $primary;
            box-shadow: 0 0 2px $black;
        }
    }
}

.collapse--width {
    width: 0 !important;
    height: 100% !important;
    opacity: 0;
    transition: width 0.3s ease-in, opacity 0.3s ease !important;

    &.show {
        width: 20% !important;
        opacity: 1 !important;
        transition: width 0.3s ease-out, opacity 0.3s ease !important;
    }
}

#navbarResponsive {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 20%;
    height: 100%;
    min-height: auto;
    background: $mostly-white;
    border-right: 1px solid $light-gray;
    box-shadow: 0 2px 3px $half-white;
    overflow: auto;

    .container {
        padding: 6rem 1rem;
    }

    a {
        position: relative;
        display: block;
        color: #000;
        font-size: 1rem;
        margin: 0.5rem 1rem;
        padding: 0 0 0.1rem;
        border-bottom: 1px solid #ccc;
        transition: 0.3s;

        i {
            color: #000;
            transition: 0.3s;
        }

        span.underline {
            position: absolute;
            left: 50%;
            right: 50%;
            bottom: 0;
            width: 0;
            height: 1px;
            background: $primary;
            transition: 0.5s;
        }

        &:hover,
        &:focus,
        &:active,
        &.active {
            color: $primary;
            text-decoration: none;
            border-color: transparent;
            transition: 0.3s;

            span.underline {
                left: 0;
                right: 0;
                width: 100%;
                height: 1px;
                transition: 0.5s;
            }

            i {
                color: $primary;
                transition: 0.3s;
            }
        }
    }

    ul {

        li {
            padding: 0;
            white-space: nowrap;
        }
    }
}

header {
    position: relative;
    height: 100vh;
    text-align: center;
    color: #fff;
    background-attachment: fixed;
    background-image: url(../img/bg-hero.jpg);
    background-position: center center;
    background-repeat: none;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .mask {
        position: absolute;
        z-index: 11;
        width: 100%;
        height: 100vh;
        background-attachment: fixed;
        background-image: url(../img/mask-hero.png);
        background-position: center center;
        background-repeat: none;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    #bgSlider {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100vh;

        .slick-slide {
            width: 100%;
            height: 100vh;
            background-attachment: fixed;
            background-position: center center;
            background-repeat: none;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
        }
    }

    .container {
        height: 100%;
    }

    #introText {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 1.375rem;
    }

    h1 {
        margin-bottom: 1.5rem;
        font-size: 3rem;
        line-height: 3rem;
    }

    #scrollDownWrap {
        position: absolute;
        top: auto;
        left: 0;
        right: 0;
        bottom: 3rem;
        text-align: center;


        #scrollDownLink {

            &:hover,
            &:focus,
            &:active {
                opacity: 0.5;
            }
        }
    }

}

section {

    .container {
        padding: 3rem 0;
    }

    .parallax {
        background-attachment: fixed;
        background-color: $secondary;
        background-position: center center;
        background-repeat: none;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;

        .mask {
            padding: 12.5rem 0;
            background-attachment: scroll;
            background-image: url(../img/mask-section.png);
            background-position: center center;
            background-repeat: none;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
        }
    }

    h2 {
        margin-top: 0;
        margin-bottom: 1rem;
        font-size: 2.5rem;

        &::after {
            content: '';
            display: block;
            width: 89px;
            height: 5px;
            margin: 3rem auto;
            background: url(../img/line-sep.png) no-repeat center center;
        }
    }

    h3 {
        margin-bottom: 75px;
        text-transform: none;
        font-family: "Droid Serif","Helvetica Neue",Helvetica,Arial,sans-serif;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
    }

}

.work-item {

    a {
        position: relative;
        display: block;

        .hover {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            transition: opacity 0.3s;

            text-align: center;
            color: #fff;
            background: rgba(0, 0, 0, 0.4);
            border: 3px solid #000;

            p {
                line-height: 1.2;
            }

            h4 {
                line-height: 1.2;
                margin: 0;

                &::after {
                    content: '';
                    display: block;
                    width: 51px;
                    height: 5px;
                    margin: 1.2rem auto;
                    background: url(../img/line-sep-sm.png) no-repeat center center;
                }
            }
        }

        &:hover {

            .hover {
                opacity: 1;
                transition: opacity 0.5s;
            }
        }
    }
}

.team-member {

    a {
        position: relative;
        display: block;

        img {
            transition: filter 0.5s;
            filter: grayscale(100%);
        }

        .hover {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            transition: opacity 0.3s;

            text-align: center;
            color: #fff;
            background: url(../img/icon-link-circle.png) no-repeat 97% 2% rgba(0, 0, 0, 0.4);
            border: 3px solid #000;

            p {
                line-height: 1.2;
            }

            h4 {
                line-height: 1.2;
            }
        }

        &:hover {

            .hover {
                opacity: 1;
                transition: opacity 0.5s;
            }

            img {
                transition: filter 0.5s;
                filter: none;
            }
        }
    }

    .add-sep {

        &::after {
            filter: grayscale(100%);
        }
    }
}

.add-sep {

    &::after {
        content: '';
        display: block;
        width: 51px;
        height: 5px;
        margin: 1.2rem auto;
        background: url(../img/line-sep-sm.png) no-repeat center center;
    }
}

.box-img {
    min-height: 155px;
}

address {

    li {
        font-style: italic;
    }
}

.social-buttons {
    font-size: 1.35rem;
    color: $dark;
    margin-bottom: 0;
}

.footer-logo-link {
    opacity: 1;
    transition: 0.5s;

    &:hover {
        opacity: 0.8;
        transition: 0.5s;
    }
}

form {

    input[type=text],
    input[type=email],
    textarea.form-control {
        background: transparent;
        border-color: transparent;
        border-bottom: 1px solid $secondary;
        border-radius: 0;
        box-shadow: none;
        resize: none;
        transition: 0.3s;

        &:focus,
        &:active {
            background: #fff;
            border-color: transparent;
            border-radius: 0;
            box-shadow: 0 0 0 1px $primary;
            resize: auto;
            transition: 0.3s;
        }
    }

    label {
        display: none;
    }

    ::placeholder {
        font-style: italic;
        color: $secondary;
        opacity: 1;
    }
}

footer {
    color: $light;
    background: $dark;
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {

}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .team-member {

        h4 {
            font-size: 1.2rem;
            margin: 0;
        }

        .btn {
            padding: 0.2rem 1.2rem;
        }
    }

    #navbarResponsive {
        &.show {
            width: 33% !important;
        }
    }

    .embed-responsive {

        &::before {
            padding-top: percentage(9 / 16);
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    body {
        font-size: 14px;
    }

    .add-sep {

        &::after {
            margin: 0.5rem auto;
        }
    }

    section {

        .parallax {

            .mask {
                padding: 9rem 0;
            }
        }
    }

    .team-member {

        h4 {
            font-size: 1.5rem;
        }

        .btn {
            font-size: 1.2rem;
            padding: 0.5rem 1.5rem;
        }
    }

    .embed-responsive {

        &::before {
            padding-top: percentage(3 / 4);
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    body {
        font-size: 12px;
    }

    .navbar-trigger {
        top: 1rem;
        left: 1rem;
    }

    #navbarResponsive {
        &.show {
            width: 50% !important;
        }

        .container {
            padding: 4.5rem 0;
        }
    }

    .btn-xl {
        padding: 1.5rem 2.5rem;
    }

    section {

        .container {
            padding: 1rem;
        }

        h2 {
            margin-top: 1rem;
            margin-bottom: 1rem;
            font-size: 1.5rem;

            &::after {
                margin: 1rem auto;
                background: url(../img/line-sep.png) no-repeat center center;
            }
        }
    }

    .team-member {
        text-align: center;
        margin-bottom: 1rem;

        a {
            display: inline-block;

            .hover {
                top: 70%;

                padding: 1rem;
                background: rgba(0, 0, 0, 0.2);
                border: none;
                opacity: 1;
            }

            &:hover {

                .hover {
                    transition: opacity 0.5s;
                    border: 3px solid #000;
                }
            }
        }
    }
}

// Extra thin devices (landscape phones, 480px height)
@media (max-height: 479.98px) {
    header {

        h1 {
            font-size: 2rem;
        }

        p {
            font-size: 1rem;
        }
    }

    #scrollDownWrap {
        bottom: 1.5rem !important;
    }

    .navbar-trigger {
        position: absolute;
        top: 1rem;
        left: 1rem;
    }

    #navbarResponsive {
        position: absolute;

        .container {
            padding: 3.5rem 0;
        }
    }

    section {

        .parallax {

            .mask {
                padding: 5.5rem 0;
            }
        }
    }

    .embed-responsive {

        &::before {
            padding-top: percentage(9 / 21);
        }
    }
}
