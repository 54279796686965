@font-face {
    font-family: 'big_john_regular';
    src: url('../fonts/big_john-webfont.woff2') format('woff2'),
         url('../fonts/big_john-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'slim_joe_regular';
    src: url('../fonts/slim_joe-webfont.woff2') format('woff2'),
         url('../fonts/slim_joe-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'amble_light';
    src: url('../fonts/Amble-Light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'amble_bold';
    src: url('../fonts/Amble-Bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
