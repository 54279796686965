.scroll-to-top {
    display: none;
    position: fixed;
    z-index: 1000;
    bottom: 2rem;
    right: 2rem;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    font-size: 1.5rem;
    border-radius: 0.25rem;
    background: $half-black;
    color: #fff;
    text-align: center;
    opacity: 0.8;
    transition: 0.3s;

    &:hover {
        background: $half-white;
        opacity: 1;
        transition: 0.3s;
    }
}

@include media-breakpoint-down(xs) {
    .scroll-to-top {
        bottom: 1rem;
        right: 1rem;
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        font-size: 1.25rem;
    }
}
