.modal-dialog {
    top: 50%;
    transform: translateY(-50%) !important;
    margin: 0 auto;

    .modal-content {
        border-radius: 0;
        border: 0.25rem solid $barely-white;

        .modal-header {
            padding: 0.5rem;

            h2 {
                font-size: 1.5rem;
            }
        }

        .modal-body {
            padding: 0;

            h3 {
                font-size: 1.2rem;
            }
        }
    }
}
