.zoomer {

    img {
        position: relative;
        z-index: 10;
        transition: transform 1s;
    }

    &:hover,
    &:focus {

        img {
            // cursor: zoom-in;
            z-index: 11;
            transition: transform 1s;
            transform: scale(1.2);
        }
    }
}

.gray-zoomer {

    a {

        img {
            position: relative;
            z-index: 10;
            transition: transform 1s, filter 0.5s;
            transform: scale(0.8);
            filter: grayscale(100%);
        }

        &:hover,
        &:focus {

            img {
                z-index: 11;
                transition: transform 1s, filter 2s;
                transform: scale(1.2);
                filter: none;
            }
        }
    }
}

.loading {
    position: relative;
    border-radius: 50%;
    border-top: 1rem solid $primary;
    border-bottom: 1rem solid $primary;
    border-left: 1rem solid $secondary;
    border-right: 1rem solid $secondary;
    box-shadow: 0 0 0.5rem $dark;
    width: 3rem;
    height: 3rem;
    padding: 1rem;
    margin: 1rem auto;
    animation: spin 2s linear infinite;
    animation-delay: 0;

    &.loading--transparent {
        border-top: 1rem solid $half-white;
        border-bottom: 1rem solid $half-white;
        border-left: 1rem solid $half-black;
        border-right: 1rem solid $half-black;
        box-shadow: 0 0 0.5rem $half-black;;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.bouncing {
    position: relative;

    &:hover {
        animation: bounce 3s infinite;
    }
}

@keyframes bounce {
    0% {
        top: 0;
    }
    50% {
        top: 1rem;
    }
    100% {
        top: 0;
    }
}
