$primary: #E89C48;
$secondary: #575757;
$secondary-dark: #333;
$dark: #212121;
$light: #f0f0f0;
$light-gray: #e4e4e4;
$almost-white: #f2f2f2;
$half-white: rgba(255,255,255,0.5);
$half-black: rgba(0,0,0,0.5);
$mostly-white: rgba(255,255,255,0.75);
$mostly-black: rgba(0,0,0,0.75);
$barely-white: rgba(255,255,255,0.35);
$barely-black: rgba(0,0,0,0.35);
$half-primary: rgba($primary,0.5);
